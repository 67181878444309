import { Link } from "react-router-dom";
import React from "react";

export default function Footer2() {
  return (
    <div className="relative h-30 mt-30 w-100 bg-black">
      <footer class="shadow bg-gray-900 w-full inset-x-0 mt-auto absolute">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div class="sm:flex sm:items-center sm:justify-between">
            <a href="#" class="flex items-center mb-4 sm:mb-0">
              <img
                src="https://flowbite.com/docs/images/logo.svg"
                class="h-8 mr-3"
                alt="Flowbite Logo"
              />
              <span class="self-center text-2xl font-semibold whitespace-nowrap text-white">
                Sessarego Remolques S.A
              </span>
            </a>
            <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <Link to={"/contacto"}>
                  <a href="#" class="mr-4 hover:underline md:mr-6 ">
                    Contacto
                  </a>
                </Link>
              </li>
              <li>
                <Link to={"/financiacion"}>
                  <a href="#" class="hover:underline">
                    Financiación
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023{" "}
            <a href="#" class="hover:underline">
              DoctaSolutions™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}
